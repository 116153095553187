import React from "react";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { useSelector } from "react-redux";
import { getIsAdmin } from "src/app/selectors";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getLocalizedString } from "@icarius-localization/strings";
import useCatalogs from "./useCatalogs";
import ListDialog from "./listDialog";
import EnableTableDialog from "./enableTableDialog";

const Catalogs = ({ history }) => {

  const {
    isLoading,
    data,
    fieldsData,
    state,
    handlers,
  } = useCatalogs();

  const isAdmin = useSelector(getIsAdmin);

  const structureGoBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handlers.handleClearSelectedRow}
      type={"goBack"}
    />

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.extensibilityScreenSelector)}
      type={"goBack"}
    />

  const enableTableItem = (gridRef) => isAdmin &&
    <CustomIconButton
      title={"Habilitar editores para tabla del sistema"}
      onClick={() => handlers.handleOpenEnableTableDialog(gridRef)}
      type={"edit"}
    />

  if (state.selectedRow) {

    const title = `Estructura de campos de la tabla: ${state.selectedRow.code} - ${state.selectedRow.name}`;

    return (
      <CommonPage
        isLoading={isLoading}
        title={title}
        gridTitle={title}
        columnDefPage={paths.catalogFields}
        rowData={fieldsData}
        handleRowClick={handlers.handleCatalogItemRowClick}
        menuItems={[structureGoBackItem]}
        hasExpand
        hasHelp
        ignoreProcessesbyPath
      >
        {
          Boolean(state.selectedRowOptionList?.length) &&
          <ListDialog
            open={Boolean(state.selectedRowOptionList?.length)}
            data={state.selectedRowOptionList}
            handleClose={handlers.handleCloseListDialog}
          />
        }
      </CommonPage>
    )
  }

  return (
    <CommonPage
      isLoading={isLoading}
      title={"Lista de tablas en el catálogo"}
      gridTitle={"Lista de tablas en el catálogo"}
      columnDefPage={paths.catalogs}
      rowData={data}
      handleRowClick={handlers.handleCatalogRowClick}
      menuItems={[goBackItem, enableTableItem]}
      hasExpand
      hasHelp
    >
      {
        Boolean(state.enableTableDialogData) &&
        <EnableTableDialog
          open={Boolean(state.enableTableDialogData)}
          isLoading={isLoading}
          data={state.enableTableDialogData}
          handleClose={handlers.handleCloseEnableTableDialog}
        />
      }
    </CommonPage>
  )
}

export default Catalogs;