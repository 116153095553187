import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData, getFieldsData } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { getCatalogFieldsAction, getCatalogsAction } from "../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useCatalogs = () => {

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowOptionList, setSelectedRowOptionList] = useState(null);
  const [enableTableDialogData, setEnableTableDialogData] = useState(null);

  const dispatch = useDispatch();

  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const fieldsData = useSelector(getFieldsData);

  useEffect(() => {
    dispatch(getCatalogsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleCatalogRowClick = (row) => {
    dispatch(getCatalogFieldsAction(row.data.code))
      .then(() => setSelectedRow(row.data))
  }

  const handleCatalogItemRowClick = (row) => {
    if (row.data?.optionList?.length) {
      setSelectedRowOptionList(row.data?.optionList);
    }
  }

  const handleCloseListDialog = () => {
    setSelectedRowOptionList(null);
  }

  const handleClearSelectedRow = () => {
    setSelectedRow(null);
  }

  const handleOpenEnableTableDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (selectedRows[0].type !== "Del sistema") {
      dispatch(openSnackbarAction({
        msg: "Solo pueden habilitarse editores para tablas del sistema. Las tablas del usuario ya están habilitadas en forma automática para la creación de editores.",
        severity: "warning",
        duration: 10000,
      }));
      return;
    }
    
    setEnableTableDialogData(selectedRows[0])
  }

  const handleCloseEnableTableDialog = () => {
    setEnableTableDialogData(null);
  }

  const state = {
    selectedRow,
    selectedRowOptionList,
    enableTableDialogData,
  };

  const handlers = {
    handleCatalogRowClick,
    handleCatalogItemRowClick,
    handleClearSelectedRow,
    handleCloseListDialog,
    handleOpenEnableTableDialog,
    handleCloseEnableTableDialog,
  };

  return {
    isLoading,
    data,
    fieldsData,
    state,
    handlers,
  }
}

export default useCatalogs;