import * as actionTypes from "./actionTypes";
import {
  getCatalogsApi,
  getCatalogFieldsApi,
  enableSystemTableEditorsApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
      errorString = getLocalizedString("validationError");
      break;
    case "IN_USE":
      errorString = "No es posible deshabilitar la creación de editores para la tabla del sistema seleccionada porque ya existe uno o más editores que la utilizan. Antes de deshabilitarla debe eliminar los editores existentes";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getCatalogsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getCatalogsApi();
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getCatalogFieldsAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FIELDS });
  try {
    let response = await getCatalogFieldsApi(`?code=${code}`);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FIELDS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FIELDS_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FIELDS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const enableSystemTableEditorsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let response = await enableSystemTableEditorsApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Actualización realizada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};