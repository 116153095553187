import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.catalogFields],
        config: {
            headerName: 'Código físico de la tabla',
            field: "tableCode",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.catalogFields],
        config: {
            headerName: 'Código físico del campo',
            field: "fieldCode",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.catalogFields],
        config: {
            headerName: 'Nombre del campo',
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.catalogFields],
        config: {
            headerName: 'Tipo',
            field: "type",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.catalogFields],
        config: {
            headerName: 'Tamaño',
            field: "size",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.catalogFields],
        config: {
            headerName: 'Tiene valores asociados',
            field: "hasOptions",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.catalogs],
        config: {
            headerName: 'Código de la tabla en catálogo',
            field: "code",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.catalogs],
        config: {
            headerName: 'Nombre de la tabla',
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.catalogs],
        config: {
            headerName: 'Código físico de la tabla',
            field: "physicalCode",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.catalogs],
        config: {
            headerName: 'Tipo de tabla',
            field: "type",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.catalogs],
        config: {
            headerName: 'Tiene editores relacionados',
            field: "hasRelatedEditors",
            filter: "agSetColumnFilter",
        }
    },
]