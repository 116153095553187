import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const GET_FIELDS = NAME + "/GET_FIELDS";
export const GET_FIELDS_REJECTED = NAME + "/GET_FIELDS_REJECTED";
export const GET_FIELDS_FULFILLED = NAME + "/GET_FIELDS_FULFILLED";

export const UPDATE = NAME + "/UPDATE";
export const UPDATE_REJECTED = NAME + "/UPDATE_REJECTED";
export const UPDATE_FULFILLED = NAME + "/UPDATE_FULFILLED";