import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Checkbox,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch } from "react-redux";
import Loader from "@icarius-common/loader";
import { enableSystemTableEditorsAction } from "@icarius-pages/catalogs/actions";
import useHandleForm from "./useHandleForm";

const EnableTableDialog = (props) => {

  const {
    open,
    isLoading,
    data,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const modify = (dataToSend) => {
    dispatch(enableSystemTableEditorsAction({ ...dataToSend, table: data.physicalCode }))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, modify, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {`Habilitar editores para la tabla del sistema: ${data.code} - ${data.name}`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container direction="column" item xs={12}>
            <div
              style={{ display: "flex", alignItems: "center", cursor: "pointer", gap: 10, marginBottom: 10 }}
              onClick={() => setFormValue(!formData.enableEditor, "enableEditor")}
            >
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {"¿Habilitar editores?"}
              </Typography>
              <Checkbox checked={formData.enableEditor} style={{ padding: 0 }} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: formData.enableEditor ? "pointer" : "",
                gap: 10,
                marginBottom: 10,
              }}
              onClick={() => formData.enableEditor && setFormValue(!formData.canDeleteRegistries, "canDeleteRegistries")}
            >
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {"¿Los editores pueden eliminar registros de la tabla?"}
              </Typography>
              <Checkbox checked={formData.canDeleteRegistries} style={{ padding: 0 }} disabled={!formData.enableEditor} />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog >
  );
}

export default EnableTableDialog;