import { useState } from "react";

const useHandleForm = (data, modifyCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "enableEditor") return Boolean(data?.enableEditor);
      if (fieldName === "canDeleteRegistries") return Boolean(data?.canDeleteRegistries);
    }

    const fieldNames = [
      "enableEditor",
      "canDeleteRegistries",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = () => {
    // si enableEditor es false y data.hasRelatedEditors es Si, es invalid. No pueden borrarse los que ya tienen tablas
    if (!formData.enableEditor && data.hasRelatedEditors === "Si") return false;
    return true;
  }

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {
        ...formData,
      };

      modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "enableEditor" && !value) {
      setFormData({
        ...formData,
        enableEditor: false,
        canDeleteRegistries: false,
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
