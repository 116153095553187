import React from "react";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography } from "@material-ui/core";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const ListDialog = (props) => {

  const { open, data, handleClose } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      maxWidth={"sm"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {'Valores asociados'}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ maxHeight: 500 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography className="whiteText" style={{ fontSize: 20, fontWeight: 600 }}>
                Código
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="whiteText" style={{ fontSize: 20, fontWeight: 600 }}>
                Nombre
              </Typography>
            </Grid>
            {
              data.map(item => {
                return (
                  <>
                    <Grid item xs={6}>
                      <Typography className="whiteText">
                        {item.key}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className="whiteText">
                        {item.value}
                      </Typography>
                    </Grid>
                  </>
                )
              })
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction isAccept text={'Cerrar'} onClick={handleClose} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ListDialog;
